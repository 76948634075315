import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../utils/server/firebase";
import React, { useState } from "react";
import Footer from "../../assets/components/Footer";
import Navbar from "../../assets/components/Navbar";
import Seo from "../../assets/components/seo";
import { sendEmail, validateEmail } from "../../utils/functions";
import { toast } from "react-toastify";

const db = getFirestore(app);

const ConcursoChocolateMexicanoOrigen = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const estados = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Estado de México",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ];
  const categorias = [
    "Barras",
    "Rellenos, Bombones, Pralines",
    "Untables",
    "Ingredientes",
    "Bebidas de chocolate",
  ];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (
        !data?.chocolatero?.trim() ||
        !data?.telefono?.trim() ||
        !data?.email?.trim() ||
        !data?.direccion?.trim() ||
        !data?.empresa?.trim() ||
        !data?.categoria?.trim() ||
        !data?.estado?.trim() ||
        !data?.ciudad?.trim()
      ) {
        return toast.warning("Favor de llenar todos los campos");
      } else if (!validateEmail(data?.email?.trim())) {
        return toast.info("Revise que el correo electrónico sea valido");
      }

      try {
        const q = query(
          collection(db, "chocolateMexicano"),
          where("email", "==", data.email)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0)
          return toast.error(
            "El email que ingresó ya se encuentra registrado para este concurso"
          );
      } catch (error) {
        return toast.warning("Ocurrio un error: " + error.message);
      }

      const docsRef = collection(db, "chocolateMexicano");
      await addDoc(docsRef, {
        chocolatero: data?.chocolatero,
        telefono: data?.telefono,
        email: data?.email,
        direccion: data?.direccion,
        empresa: data?.empresa,
        estado: data?.estado,
        ciudad: data?.ciudad,
        categoria: data?.categoria,
        createAt: new Date(),
      });

      toast.success(
        "Pre-registro finalizado, será redirigido a un sitio externo para completar su registro"
      );

      setTimeout(() => {
        typeof window !== "undefined" &&
          window.location.replace(
            "https://enter.chocolateawards.com/competitions/mexican/1er-concurso-nacional-de-chocolate-mexicano-2023/"
          );
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error(
        "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo
        title="Primer Concurso Nacional de Chocolate Méxicano"
        description="¡No pierdas la oportunidad de llevar tu #chocolate al siguiente nivel! Sé parte del Primer Concurso Nacional de Chocolate Mexicano y muestra al mundo tu dulce talento"
      />
      <Navbar />
      <Grid container justifyContent="center" marginY={12}>
        <Typography variant="h2">
          Muchas gracias por tu interés en participar. Este concurso ha
          concluido su etapa de registro!
        </Typography>
        {/* <Grid container maxWidth="sm" spacing={2} paddingX={4}>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/09/04/20230904121254-36a017c6-me.png"
                width="100%"
                height="100%"
                style={{ maxWidth: 150, objectFit: "cover" }}
                alt="Logo Chocolate Awards"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                Pre-registro al Primer Concurso Nacional de Chocolate Mexicano
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre del chocolatero"
              fullWidth
              required
              value={data?.chocolatero || ""}
              onChange={(e) => {
                setData({ ...data, chocolatero: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre de la empresa"
              fullWidth
              required
              value={data?.empresa || ""}
              onChange={(e) => {
                setData({ ...data, empresa: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Dirección"
              fullWidth
              required
              value={data?.direccion || ""}
              onChange={(e) => {
                setData({ ...data, direccion: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Teléfono"
              fullWidth
              required
              value={data?.telefono || ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value == "" || regex.test(e.target.value)) {
                  setData({ ...data, telefono: e.target.value });
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Correo electrónico"
              fullWidth
              required
              value={data?.email || ""}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Categoria"
              color="primary"
              name="categoria"
              value={data?.categoria || ""}
              onChange={(e) => {
                setData({ ...data, categoria: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione una categoria
              </MenuItem>
              {categorias.map((categoria, key) => (
                <MenuItem key={key} value={categoria}>
                  {categoria}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Estado"
              color="primary"
              name="estado"
              value={data?.estado || ""}
              onChange={(e) => {
                setData({ ...data, estado: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione un estado
              </MenuItem>
              {estados.map((estado, key) => (
                <MenuItem key={key} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Ciudad"
              fullWidth
              required
              value={data?.ciudad || ""}
              onChange={(e) => {
                setData({ ...data, ciudad: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? "Guardando pre-registro" : "Finalizar pre-registro"}
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      <Footer />
    </Container>
  );
};

export default ConcursoChocolateMexicanoOrigen;
